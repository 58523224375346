import { Drawer} from "antd";
import React, {SyntheticEvent, useCallback, useState} from "react";
import {getter, setter, useGlobalValue} from "elum-state/react";
import {ANCHOR, ISidebars, LANGUAGE_CODE, PAGE, Pages, PAYLOAD, SIDEBARS, USER} from "../../../../state/elum";
import styles from './Task.module.scss'
import {game} from "../../../../Api";
import {ITask} from "../../../Screens/Earn/Body";
import {taskTexts} from "./texts";
import {useEnqueueSnackbar} from "../../../../Hooks/useSnackbar";
import {Telegram} from "../../../../../index";
import {useTonAddress, useTonConnectModal, useTonConnectUI} from "@tonconnect/ui-react";
import {CloseOutlined} from "@ant-design/icons";
import {Photo} from "../../Avatar";
import {useAdsgram, useAdsgramCallback} from "../../../../Hooks/useAdsgram";
import {BalanceDiamond} from "../../../../../Assets/icons";
import clsx from "clsx";
import {Lotties} from "../../../../../Assets/icons/LottiesObj";
import {Sidebar} from "../../PopUps/Sidebar";

export type IDailyBonus = {
    collecting_days: number
    can_collect: boolean
}
type IErrors = 1005 | 1006
const ERRORS = [1005, 1006] as IErrors[]

export const Task = () => {
    const { state, open: openTon, close } = useTonConnectModal();
    const [open, setOpen] = useState(getter(SIDEBARS) === 'task');
    const [loading, setLoading] = useState<boolean>(true);
    const [task, setTask] = React.useState<ITask>({} as ITask)
    const {openSnackbar}= useEnqueueSnackbar()
    const langCode = useGlobalValue(LANGUAGE_CODE)
    const {id, payload} = getter(PAYLOAD)

    const getTaskById = async () => {
        setLoading(true)
        try {
            const {data} = await game.tasks.getById(id)
            setTask(data)
            setLoading(false)
        } catch (e) {

        }
    }

    const {onError, onReward} = useAdsgramCallback('task', getTaskById)


    const onClose = () => {
        setter(SIDEBARS, '')
    };

    const classNames = {
        mask: styles.sidebar_mask,
        content: styles.sidebar_content,
        wrapper: styles.sidebar_wrapper,
        body: styles.sidebar_body
    }

    const Hooks = {
        ads: useAdsgram(({ blockId: "2083", onReward, onError }))
    }


    React.useEffect(() => {
        getTaskById()
    },[])

    const checkTask = async () => {
        try {
            const {data} = await game.tasks.check(id)

            if (data.success) {
                setTimeout(() => setter(SIDEBARS, ''), 200)
                setter(ANCHOR, Math.random())
                return openSnackbar({message: taskTexts.snackbar.success[langCode], variant: 'success'})
            }
            const error_code =
                ERRORS.indexOf(data.error.error_code) >= 0
                    ? Number(data.error.error_code) as IErrors: 'unknown'
            return openSnackbar({message: taskTexts.snackbar.error[error_code][langCode], variant: 'error'})

        } catch (e) {
            return openSnackbar({message: taskTexts.snackbar.error.unknown[langCode], variant: 'error'})
        }
    }

    const openTask = async () => {
        if (payload.includes('setter')) {
            return setterPayload(payload)
        }
        if (payload.includes('hooks')) {
            return hooksPayload(payload)
        }
        if (payload.includes('open_app')) {
            return openappPayload(payload)
        }
        if (payload.startsWith('make')) {
            return makePayload(payload)
        }
        Telegram.WebApp.openTelegramLink(payload)
    }

    const hooksPayload = (pl: string) => {
        const [_, hook] = pl.split(':')
        switch (hook) {
            case 'ads':
                return Hooks.ads()
        }

    }
    const openappPayload = (pl: string) => {
        const [_, link] = pl.split('|')
        game.tasks.check(id, true)
        return Telegram.WebApp.openTelegramLink(link)

    }
    const makePayload = (pl: string) => {
        const [_, hook] = pl.split(':')
        switch (hook) {
            case 'transaction':
                return make()
        }

    }
    const setterPayload = (pl: string) => {
        const [_, type, action] = pl.split(':')
        switch (type) {
            case 'page':
                onClose()
                return setter(PAGE, action as Pages)
            case 'ton':
                onClose()
                return openTon()
            case 'sidebar':
                return setter(SIDEBARS, action as ISidebars)


        }
    }


    const userFriendlyAddress = useTonAddress();


    const [tonConnectUI, setOptions] = useTonConnectUI();

    const make = async () => {


        if (userFriendlyAddress === '') {
            onClose()
            return openTon()
        }

        const paymentParams = {
            validUntil: Math.floor(Date.now() / 1000) + 3600,
            messages: [
                {
                    address: 'UQA7Ec2g32NFV4b6sBLBeaFiEm0eo5kXxdm-ReSjEf-mL5TN',
                    amount: '200000000',
                },
            ],
        };

        try {
            const data = await tonConnectUI.sendTransaction(paymentParams);
            if (data.boc) {
                setter(USER, (state) => ({...state, special_task_state: 3}))
                return game.transaction.make(data)
            }
        } catch (e) {
            console.log(e)
        }


    }

    const genAward = (el:  ITask | undefined) => {
        switch (el?.payload){

            case 'make:transaction':
                return <><p>+ {el.award} min. and + 50 </p><BalanceDiamond/></>
            case 'setter:sidebar:donuts':
                return <><p>+ {el.award} min. and + 10 </p><BalanceDiamond/></>
            default:
                return `+ ${el?.award} min.`
        }
    }


    if (loading) return <></>

    return (
        <Sidebar
            // classNames={classNames}
            // placement={'bottom'}
            // closable={false}
            onClose={onClose}
            // open={open}
        >


            <div className={styles.content}>


                {

                    task.icon && (task.icon.includes('lottie') ?
                        <div className={clsx([styles.content_img, styles.content_img__lottie])}>
                            {Lotties[task.icon.replace('lottie:', '') as keyof typeof Lotties]}
                        </div> :
                        <Photo src={`./assets/tasks/${task.icon}`} className={styles.content_img}/>)

                }

                <p className={styles.content_award}>{genAward(task)}</p>

                <p className={styles.content_title}>{task.title}</p>

                <p>{task.description}</p>

                <div className={styles.content_action}>
                    <button className={`btn_primary`} onClick={openTask}>{taskTexts.btn.action[langCode]}</button>
                    <button className={`btn_primary-outlined`} onClick={checkTask}>{taskTexts.btn.check[langCode]}</button>
                </div>

            </div>
        </Sidebar>
    )
}

export const TappTask = () => {
    const [open, setOpen] = useState(getter(SIDEBARS) === 'tapp_task');
    const task = getter(PAYLOAD)

    const onClose = () => {
        setOpen(false);
        setTimeout(() => {
                setter(PAYLOAD, {})
                setter(SIDEBARS, '')
            }
            , 500)
    };


    const classNames = {
        mask: styles.sidebar_mask,
        content: styles.sidebar_content,
        wrapper: styles.sidebar_wrapper,
        body: styles.sidebar_body
    }



    const completeTask = async (e: SyntheticEvent, el: ITask) => {
        e.preventDefault()
        e.stopPropagation()
        Telegram.WebApp.openTelegramLink(el.payload)
        game.tasks.click(el.id)
    }

    return (
        <Sidebar
            // classNames={classNames}
            // placement={'bottom'}
            // closable={false}
            onClose={onClose}
            // open={open}
        >

            <div className={styles.content}>


               <Photo src={task.icon} className={styles.content_img}/>

                <p className={styles.content_award}>+ {task.award} min</p>

                <p className={styles.content_title}>{task.title}</p>

                <p>{task.description}</p>

                <div className={styles.content_action}>
                    <button className={`btn_primary`} onClick={(e) => completeTask(e, task)}>{task.btn_label}</button>
                </div>

            </div>
        </Sidebar>
    )
}