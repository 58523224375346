import {FC} from "react";
import {Skeleton} from "../../../UI/Loaders/Skeleton";
import {Tools} from "../../../../Tools";
import {ILangProp} from "../../../../../Struct/Screens/Top";
import {Divider} from "../../../UI/Divider";
import {Friends} from "../../../UI/Menu/icons";
import styles from './AllUsersWay.module.scss'

interface IAllUsersWay extends ILangProp {
    loading: boolean,
    users: number,
}

export const AllUsersWay: FC<IAllUsersWay> = ({loading, users, langCode}) => {


    if (loading) return <Skeleton/>
    return (
        <div>
            <Divider orientation={'center'} className={styles.divider}><Friends /> <p>{Tools.NumberFormatter(users)} holders</p></Divider>
        </div>
    )
}
