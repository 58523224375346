
import {useGlobalValue} from "elum-state/react";
import {MODALS} from "../../../state/elum";
import {EnterPromoModal} from "./EnterPromo";
import {SpecialTask} from "./SpecialTask";


const modals = {
    promocodes: <EnterPromoModal/>,
    special_task: <SpecialTask/>,
    '': <></>
}

export const Modals = () => {
    const modal = useGlobalValue(MODALS)

    return (
        <>
            {modals[modal]}
        </>

    )
}
