import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.scss'
import App from './App';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


export const {Telegram} = window

if (Telegram.WebApp.platform === 'unknown' && false) {
    root.render(<></>)
} else {


    let headerColor = '#040215'

    if (Telegram.WebApp.colorScheme === 'dark') {
        headerColor =  '#040215'
    }

    Telegram.WebApp.setHeaderColor(headerColor)

    root.render(
        <App />
    );
}



