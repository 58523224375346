import { useState, useEffect, useMemo } from "react";
import { useGetHillInfo } from "../../../../Hooks/useApi";
import clsx from "clsx";
import styles from './Hill.module.scss';
import { Telegram } from "../../../../../index";
import { Photo } from "../../../UI/Avatar";
import { API_URL } from "../../../../Api/config";
import {getter} from "elum-state/react";
import {USER} from "../../../../state/elum";


const setOnUserHill = (tid: number, username: string) => ({
    "tid": tid,
    "stepped_in": Date.now() / 1000 >> 0,
    "username": username
})

export const Hill = () => {
    const { hillInfo, isLoading, setHillInfo } = useGetHillInfo();
    const [elapsedTime, setElapsedTime] = useState("");

    useEffect(() => {
        if (!hillInfo || !hillInfo.on_hill || !hillInfo.on_hill.stepped_in) return;

        const startTime = hillInfo.on_hill.stepped_in * 1000;
        const updateElapsedTime = () => {
            const now = Date.now();
            const diff = now - startTime;
            const hours = Math.floor((diff / (1000 * 60 * 60)) % 24).toString().padStart(2, "0");
            const minutes = Math.floor((diff / (1000 * 60)) % 60).toString().padStart(2, "0");
            const seconds = Math.floor((diff / 1000) % 60).toString().padStart(2, "0");
            setElapsedTime(`${hours}:${minutes}:${seconds}`);
        };

        updateElapsedTime();
        const interval = setInterval(updateElapsedTime, 1000);
        return () => clearInterval(interval);
    }, [hillInfo?.on_hill]);

    const buy = () => {
        if (!hillInfo) return;
        Telegram.WebApp.openInvoice(hillInfo.invoice_link, (response) => {
            if (response === 'paid') {
                const user = getter(USER)
                setHillInfo({...hillInfo, on_hill: setOnUserHill(user.tid, user.username)});
            }
        });
    };

    const Avatar = useMemo(() => {
        if (hillInfo && hillInfo.on_hill) {
            return <Photo src={`${API_URL}/users.photo/${hillInfo.on_hill.tid}`} />;
        }
        return null;
    }, [hillInfo?.on_hill?.tid]);

    return (
        <div className={clsx('fade-in', styles.wrapper)}>
            <p>King the hill</p>
            <div className={clsx(styles.hill)}>
                {hillInfo && hillInfo.on_hill && (
                    <div className={styles.hill_body}>
                        <div className={styles.hill_background}>
                            {/*<img src={'./assets/misc/crown.png'} alt="Crown" />*/}
                            {/*<img src={'./assets/misc/hill.png'} alt="Hill" />*/}
                            <img src={'./assets/misc/crown.png'} alt="Crown" />
                        </div>
                        <div className={styles.hill_info}>
                            {Avatar}
                            <div className={styles.hill_about}>
                                <p>{hillInfo.on_hill.username ?? `#${hillInfo.on_hill.tid}`}</p>
                                <div className={styles.hill_timer}>
                                    <p>{elapsedTime}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <button className={styles.button} onClick={buy}>
                    Become a king
                </button>
            </div>
        </div>
    );
};
