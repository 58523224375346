import {Skeleton} from "../../../UI/Loaders/Skeleton";
import React, {FC} from "react";
import {ITask} from "./index";
import styles from './Body.module.scss'
import {setter} from "elum-state/react";
import {PAYLOAD, SIDEBARS} from "../../../../state/elum";
import {BalanceDiamond} from "../../../../../Assets/icons";
import clsx from "clsx";
import {Lotties} from "../../../../../Assets/icons/LottiesObj";

export type ITasksBody = {
    tasks: ITask[] | undefined[]
}

export const TasksBody: FC<ITasksBody> = ({tasks}) => {

    const clickOnTask = (id: number | undefined, payload: string | undefined) => {
        if (!id) return 0

        setter(SIDEBARS, 'task')
        setter(PAYLOAD, {id, payload})

    }
    const genFooter = (el:  ITask | undefined) => {
        switch (el?.id){
            case 8:
                return <><p>+ {el.award} min. and + 10 </p><BalanceDiamond/></>
            default:
                return `+ ${el?.award} min.`

        }
    }
    return (
        <>
            {tasks.map((el, i) =>
                <div className={styles.body_tasks__row} onClick={() => clickOnTask(el?.id, el?.payload)} key={`${i}_task`}>
                    {
                        el ?
                            <>
                                {
                                    el.icon.includes('lottie') ?
                                        <div className={clsx([styles['body_tasks__row-img'], styles['body_tasks__row-lottie']])}>
                                            {Lotties[el.icon.replace('lottie:', '') as keyof typeof Lotties]}
                                        </div> :
                                        <img src={`./assets/tasks/${el.icon}`}
                                             className={styles['body_tasks__row-img']}/>
                                }
                                <div className={styles['body_tasks__row-info']}>
                                    <p className={styles['body_tasks__row-title']}>{el.title}</p>
                                    <p className={styles['body_tasks__row-award']}>{genFooter(el)}</p>
                                </div>
                            </>
                            : <Skeleton/>
                    }
                </div>
            )}
        </>
    )
}
