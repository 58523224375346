import React, {FC} from "react";
import {MenuPanel} from "./MenuPanel";
import {IProps} from "../../../types/props";
import styles from './MenuPanel.module.scss'
import clsx from "clsx";

export const MenuWrapper: FC<IProps> = ({children}) => {
    const [height, setHeight] = React.useState(0)

    React.useEffect(() => {
        setHeight(document.getElementById('menu')!.clientHeight)
    }, [])

    return (
        <>
            <div className={clsx(['center',styles.wrapper])} style={{height: `calc(100vh - ${height + 10}px)`}}>
            {children}
            </div>
            <MenuPanel/>
        </>
    )
}
