import {Divider} from "../../../../UI/Divider";
import {Tools} from "../../../../../Tools";
import {FC} from "react";
import {Skeleton} from "../../../../UI/Loaders/Skeleton";
import {friendInfo} from "../texts";
import {useGlobalValue} from "elum-state/react";
import {LANGUAGE_CODE} from "../../../../../state/elum";
import {Friends} from "../../icons";
import styles from "../../../Top/AllUsersWay/AllUsersWay.module.scss";

type IFriendsInfo = {
    loading: boolean
    count: number
}

export const FriendsInfo: FC<IFriendsInfo> = ({count, loading}) => {
    const langCode = useGlobalValue(LANGUAGE_CODE)


    if (loading) return <Skeleton/>

    return (
        <Divider orientation='center' className={styles.divider}>
            <Friends/>
            <p>{Tools.Spacer(count)} {friendInfo[langCode]}</p>
        </Divider>
    )
}
