import {setter} from "elum-state/react";
import {PAYLOAD, SIDEBARS} from "../../../../state/elum";
import {ITask} from "./index";
import styles from "./Body.module.scss";
import React, {FC, SyntheticEvent} from "react";
import {ITasksBody} from "./Tasks";
import clsx from "clsx";
import {Telegram} from "../../../../../index";
import {game} from "../../../../Api";

export const TappAdsBody: FC<ITasksBody> = ({tasks}) => {
    const clickOnTask = (task: ITask | undefined) => {
        if (!task) return 0

        setter(SIDEBARS, 'tapp_task')
        setter(PAYLOAD, task)

    }

    const btnClick = async (e: SyntheticEvent, el: ITask) => {

        e.preventDefault()
        e.stopPropagation()
        Telegram.WebApp.openTelegramLink(el.payload)
        game.tasks.click(el.id)
    }

    return (
        <>
            {tasks.map((el, i) =>
                el &&
                <div className={clsx([styles.body_special__row])}
                     key={`${i}_tapp_task`}  onClick={() => clickOnTask(el)}>
                    <div className={styles.body_special__box}>
                        <img src={el.icon}
                             className={styles['body_special__row-img']}/>
                        <div className={styles['body_special__row-info']}>
                            <p className={styles['body_special__row-title']}>{el.title}</p>
                            <p className={styles['body_special__row-award']}>+ {el?.award} min.</p>
                        </div>
                    </div>
                    <div className={styles.body_special__footer}>
                        <button onClick={e => btnClick(e,el)}>{el.btn_label}</button>
                        <p>{el.description}</p>
                    </div>
                </div>
            )}
        </>
    )
}