import {Sidebar} from "../../PopUps/Sidebar";
import {setter} from "elum-state/react";
import {SIDEBARS} from "../../../../state/elum";

export const Test = () => {

    const onClose = () => {
        setter(SIDEBARS, '')
    }

    return (
        <Sidebar onClose={onClose}>
            <p>123</p>
        </Sidebar>
    )
}