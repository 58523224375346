import {HoverBorderGradient} from "../Effects/hover-border-gradient";
import React, {FC} from "react";
import clsx from "clsx";

type TCometBorder = React.PropsWithChildren<
    {
        className?: string;
        color?: string
    } & React.HTMLAttributes<HTMLElement>
>

export const CometBorder: FC<TCometBorder> = ({children, className, color, ...props}) => {
    return (
        <HoverBorderGradient
            containerClassName="rounded-full w-full"
            as="button"
            color={color}
            className={clsx([
                "w-full flex justify-between text-center dark:bg-black bg-white text-black dark:text-white items-center space-x-2", className])}
            {...props}
        >

            {children}
            {/*<span className='opacity-50 text-xs'>{boost.title}</span>*/}
            {/*<span>{formatTime(remainingTime)}</span>*/}
        </HoverBorderGradient>
    )
}