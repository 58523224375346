import styles from './Head.module.scss'
import {earnHeader} from "./texts";
import {setter, useGlobalValue} from "elum-state/react";
import {LANGUAGE_CODE, MODALS, SIDEBARS} from "../../../../state/elum";

export const EarnHead = () => {
    const langCode = useGlobalValue(LANGUAGE_CODE)

    const openDaily = () => setter(SIDEBARS, 'daily_bonus')
    const openPromo = () => setter(SIDEBARS, 'promocodes')

    return (
        <div className={styles.header}>
            {/*<Logo style={{width: '100px'}}/>*/}
            <button className={styles.daily} onClick={openDaily}>
                <p>
                    {earnHeader.button[langCode]}
                </p>
                <div className={styles.daily_gifts}>
                    <img src={'./assets/misc/gift.png'}/>
                    <img src={'./assets/misc/gift.png'}/>
                </div>
            </button>
            <button className={styles.promo} onClick={openPromo}>{earnHeader.promo[langCode]}</button>

        </div>
    )
}
