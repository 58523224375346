import {Drawer} from "antd";
import React, {useState} from "react";
import {getter, setter} from "elum-state/react";
import { SIDEBARS} from "../../../../state/elum";
import styles from './SpecialTask.module.scss'


export const SpecialTask = () => {
    const [open, setOpen] = useState(getter(SIDEBARS) === 'special_task');


    const onClose = () => {
        setOpen(false);
        setter(SIDEBARS, '')
    };

    const classNames = {
        mask: styles.sidebar_mask,
        content: styles.sidebar_content,
        wrapper: styles.sidebar_wrapper,
        body: styles.sidebar_body
    }




    return (
        <Drawer
            classNames={classNames}
            placement={'bottom'}
            closable={false}
            onClose={onClose}
            open={open}
        >

        </Drawer>
    )
}


