import styles from './Top.module.scss'
import {TopHeader} from "../../../Engine/Components/Screens/Top/Header";
import {TopAction} from "../../../Engine/Components/Screens/Top/Action/Action";
import {TopAllUsersWay} from "../../../Engine/Components/Screens/Top/AllUsersWay";
import {useGlobalValue} from "elum-state/react";
import {LANGUAGE_CODE} from "../../../Engine/state/elum";
import {TopBody} from "../../../Engine/Components/Screens/Top/Body";
import useScroll from "../../../Engine/Hooks/useScroll";
import clsx from "clsx";
import {Header} from "../../../Engine/Components/PreHeader";
import {Hill} from "../../../Engine/Components/Screens/Top/Hill";

export type ILangProp = {
    langCode: 'en' | 'ru'
}
export const Top = () => {
    const langCode = useGlobalValue(LANGUAGE_CODE)
    const height = document.getElementById('menu')!.clientHeight

    const scrollToEnd  = useScroll('top')


    return (
        <div id='top' className={clsx([styles.top,'center', 'fade-in'])} style={{paddingBottom: `${height + 10}px`}}>
            <Hill/>
            {/*<Header.Top/>*/}
            {/*<TopHeader/>*/}
            <TopAction langCode={langCode}/>
            <TopAllUsersWay langCode={langCode}/>
            <TopBody scrollToEnd={scrollToEnd}/>
        </div>
    )
}
