import {getter} from "elum-state/react";
import {USER} from "../../../../../state/elum";
import {tgLink} from "../../../../../Api/config";
import styles from '../FriendsHeader.module.scss'
import {CopyOutlined} from "@ant-design/icons";
import {useEnqueueSnackbar} from "../../../../../Hooks/useSnackbar";
import {FC} from "react";
import {header} from "../texts";
import {Telegram} from "../../../../../../index";
import {Link} from "../../icons";
import {Divider} from "../../../../UI/Divider";


type IRefererBox = {
    langCode: 'en' | 'ru'
}



export const RefererBox: FC<IRefererBox> = ({langCode}) => {
    const user = getter(USER)
    const {openSnackbar} = useEnqueueSnackbar()
    const refererLink = `${tgLink}?startapp=${user.tid}`


    const copyToBuffer = () => {
        navigator.clipboard.writeText(refererLink).then(r => {
            openSnackbar({message: header.snackbar.success[langCode], variant: 'info'})
        }).catch(_ => {
            openSnackbar({message: header.snackbar.error[langCode], variant: 'error'})
        })

    }

    const shareViaContacts = () => {
        // const invoice = 'https://t.me/$ecjW0QazsUgOBwAA25dDHm_GIiM'
        // Telegram.WebApp.openInvoice(invoice, (q) => {
        //     console.log(q)
        // })
        const link = `https://t.me/share/url?url=${refererLink}`
        Telegram.WebApp.openTelegramLink(link)
    }


    return (
        <div className={styles.referer} >
            <div className={styles.referer_copy}>
                <div className={styles.referer_copy__link}>
                    <Link/>
                    <p>{refererLink.replace('https://', '')}</p>
                </div>
                <div className={styles.referer_copy__divider}/>
                <div onClick={copyToBuffer} className={styles.referer_copy__btn}>
                    <CopyOutlined />
                </div>

            </div>
            <div className={styles.referer_main__btn} onClick={shareViaContacts}>
                <p>{header.main_btn[langCode]}</p>
            </div>

        </div>
    )

}
