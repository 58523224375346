import {FC} from "react";
import {header} from "../texts";
import styles from '../FriendsHeader.module.scss'
import clsx from "clsx";

type ICards = {
    langCode: 'en'|'ru';
}

export const Cards: FC<ICards> = ({langCode}) => {
    return (
        <div className={clsx([styles.cards])}>
            <div className={clsx([styles.cards_item_1, styles.cards_item])}>
                <p>+ {header.cards.first[langCode]}</p>
            </div>

            <div className={clsx([styles.cards_item_2, styles.cards_item])}>
                <pre>
                    {"+ 72 hours for each\nfriend with \npremium"}
                </pre>

                <svg width="49" height="38" viewBox="0 0 49 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M44.3496 17.7374L32.8473 16.693C32.4262 16.6548 32.0605 16.3875 31.8963 15.9979L27.4586 5.46952C27.0652 4.5362 25.7427 4.5362 25.3493 5.46952L20.9116 15.9979C20.7474 16.3875 20.3817 16.6548 19.9606 16.693L8.45827 17.7374C7.44683 17.8293 7.04719 19.0951 7.82275 19.7511L12.853 24.0058C13.6515 24.6813 14.6882 25.0068 15.7296 24.9089L25.4609 23.9956C26.4314 23.9045 26.8533 25.1912 26.0173 25.6925L17.5486 30.7697C16.6467 31.3103 16.0022 32.193 15.7616 33.2164L14.2629 39.5901C14.0295 40.5831 15.1136 41.3624 15.9805 40.8245L25.8006 34.7303C26.1703 34.5009 26.6381 34.5009 27.0075 34.7303L36.8277 40.8245C37.6946 41.3624 38.7787 40.5831 38.5453 39.5901L35.8832 28.2685C35.7846 27.8494 35.9296 27.4106 36.2581 27.1327L44.9854 19.7513C45.761 19.0953 45.3613 17.8295 44.3499 17.7377L44.3496 17.7374Z"
                        fill="white"/>
                    <path
                        d="M13.785 5.16146L14.2376 6.90178C14.2603 6.9894 14.3287 7.05779 14.4163 7.08051L16.1566 7.53306C16.3062 7.572 16.3062 7.78418 16.1566 7.82287L14.4163 8.27542C14.3287 8.29814 14.2603 8.36653 14.2376 8.45415L13.785 10.1945C13.7461 10.344 13.5339 10.344 13.4952 10.1945L13.0427 8.45415C13.02 8.36653 12.9516 8.29814 12.8639 8.27542L11.1236 7.82287C10.9741 7.78393 10.9741 7.57175 11.1236 7.53306L12.8639 7.08051C12.9516 7.05779 13.02 6.9894 13.0427 6.90178L13.4952 5.16146C13.5342 5.01194 13.7463 5.01194 13.785 5.16146Z"
                        fill="white"/>
                    <path
                        d="M40.8905 7.78987L41.3431 9.5302C41.3658 9.61782 41.4342 9.68621 41.5218 9.70893L43.2621 10.1615C43.4116 10.2004 43.4116 10.4126 43.2621 10.4513L41.5218 10.9038C41.4342 10.9266 41.3658 10.995 41.3431 11.0826L40.8905 12.8229C40.8516 12.9724 40.6394 12.9724 40.6007 12.8229L40.1481 11.0826C40.1254 10.995 40.057 10.9266 39.9694 10.9038L38.2291 10.4513C38.0796 10.4123 38.0796 10.2002 38.2291 10.1615L39.9694 9.70893C40.057 9.68621 40.1254 9.61782 40.1481 9.5302L40.6007 7.78987C40.6396 7.64035 40.8518 7.64035 40.8905 7.78987Z"
                        fill="white"/>
                    <path
                        d="M8.52746 25.055L8.98001 26.7953C9.00273 26.883 9.07112 26.9513 9.15874 26.9741L10.8991 27.4266C11.0486 27.4656 11.0486 27.6777 10.8991 27.7164L9.15874 28.169C9.07112 28.1917 9.00273 28.2601 8.98001 28.3477L8.52746 30.088C8.48852 30.2376 8.27634 30.2376 8.23765 30.088L7.7851 28.3477C7.76238 28.2601 7.69399 28.1917 7.60637 28.169L5.86605 27.7164C5.71653 27.6775 5.71653 27.4653 5.86605 27.4266L7.60637 26.9741C7.69399 26.9513 7.76238 26.883 7.7851 26.7953L8.23765 25.055C8.27659 24.9055 8.48877 24.9055 8.52746 25.055Z"
                        fill="white"/>
                    <path
                        d="M6.92462 0.748827L7.22566 1.9063C7.24088 1.96446 7.28631 2.01014 7.34448 2.02512L8.50195 2.32615C8.60129 2.35211 8.60129 2.49315 8.50195 2.51886L7.34448 2.8199C7.28631 2.83512 7.24063 2.88055 7.22566 2.93871L6.92462 4.09619C6.89866 4.19553 6.75763 4.19553 6.73192 4.09619L6.43088 2.93871C6.41565 2.88055 6.37022 2.83487 6.31206 2.8199L5.15459 2.51886C5.05524 2.4929 5.05524 2.35187 5.15459 2.32615L6.31206 2.02512C6.37022 2.00989 6.4159 1.96446 6.43088 1.9063L6.73192 0.748827C6.75788 0.64948 6.89891 0.64948 6.92462 0.748827Z"
                        fill="white"/>
                    <path
                        d="M43.1524 25.8982L43.4534 27.0557C43.4687 27.1139 43.5141 27.1596 43.5723 27.1745L44.7297 27.4756C44.8291 27.5015 44.8291 27.6426 44.7297 27.6683L43.5723 27.9693C43.5141 27.9845 43.4684 28.03 43.4534 28.0881L43.1524 29.2456C43.1264 29.3449 42.9854 29.3449 42.9597 29.2456L42.6587 28.0881C42.6434 28.03 42.598 27.9843 42.5398 27.9693L41.3824 27.6683C41.283 27.6423 41.283 27.5013 41.3824 27.4756L42.5398 27.1745C42.598 27.1593 42.6437 27.1139 42.6587 27.0557L42.9597 25.8982C42.9857 25.7989 43.1267 25.7989 43.1524 25.8982Z"
                        fill="white"/>
                    <path
                        d="M2.83388 17.6635L3.01885 18.3745C3.02808 18.4101 3.05604 18.4381 3.09198 18.4476L3.80289 18.6326C3.86405 18.6485 3.86405 18.7351 3.80289 18.7511L3.09198 18.9361C3.05629 18.9453 3.02833 18.9733 3.01885 19.0092L2.83388 19.7201C2.81791 19.7813 2.73129 19.7813 2.71556 19.7201L2.5306 19.0092C2.52136 18.9735 2.4934 18.9456 2.45746 18.9361L1.74655 18.7511C1.68539 18.7351 1.68539 18.6485 1.74655 18.6326L2.45746 18.4476C2.49315 18.4384 2.52111 18.4104 2.5306 18.3745L2.71556 17.6635C2.73154 17.6024 2.81815 17.6024 2.83388 17.6635Z"
                        fill="white"/>
                    <path
                        d="M35.3153 0.0458671L35.5003 0.756775C35.5095 0.79247 35.5375 0.820427 35.5734 0.829913L36.2843 1.01488C36.3455 1.03085 36.3455 1.11747 36.2843 1.1332L35.5734 1.31816C35.5377 1.3274 35.5098 1.35536 35.5003 1.3913L35.3153 2.10221C35.2994 2.16336 35.2127 2.16336 35.197 2.10221L35.012 1.3913C35.0028 1.35561 34.9748 1.32765 34.9389 1.31816L34.228 1.1332C34.1668 1.11722 34.1668 1.0306 34.228 1.01488L34.9389 0.829913C34.9746 0.820677 35.0026 0.79272 35.012 0.756775L35.197 0.0458671C35.213 -0.015289 35.2996 -0.015289 35.3153 0.0458671Z"
                        fill="white"/>
                    <path
                        d="M1.84454 33.7015L2.14558 34.8589C2.16081 34.9171 2.20624 34.9628 2.2644 34.9778L3.42187 35.2788C3.52122 35.3047 3.52122 35.4458 3.42187 35.4715L2.2644 35.7725C2.20624 35.7878 2.16056 35.8332 2.14558 35.8914L1.84454 37.0488C1.81858 37.1482 1.67755 37.1482 1.65184 37.0488L1.3508 35.8914C1.33557 35.8332 1.29014 35.7875 1.23198 35.7725L0.0745106 35.4715C-0.0248369 35.4455 -0.0248369 35.3045 0.0745106 35.2788L1.23198 34.9778C1.29014 34.9625 1.33582 34.9171 1.3508 34.8589L1.65184 33.7015C1.6778 33.6021 1.81883 33.6021 1.84454 33.7015Z"
                        fill="white"/>
                </svg>

            </div>

        </div>
    )
}