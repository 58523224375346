import styles from './sidebar.module.scss'
import React, { FC, useEffect, useState } from "react";
import { IProps } from "../../../../types/props";
import clsx from "clsx";
import {Cross} from "../entities/cross";

interface ISidebar extends IProps {
    onClose: () => void;
    title?: string
    className?: {
        main?: string
        overlay?: string,
        wrapper?: string,
    }
}

export const Sidebar: FC<ISidebar> = ({ children, onClose, title, className }) => {
    const [menuHeight, setMenuHeight] = useState(0);

    useEffect(() => {
        setMenuHeight(document.getElementById('menu')!.clientHeight);
    }, []);

    const onCloseHandler = () => {
        document.querySelector(`.${styles.sidebar_overlay}`)?.classList.add(styles.fadeOut);
        document.querySelector(`.${styles.sidebar_wrapper}`)?.classList.add(styles.sliderOut);
        setTimeout(() => { onClose(); }, 200);
    };

    return (
        <div className={clsx([styles.sidebar, className?.main])}>
            <div className={clsx([styles.sidebar_overlay, className?.overlay])} onClick={onCloseHandler} />

            <div className={clsx([styles.sidebar_wrapper, className?.wrapper])} style={{bottom: menuHeight}}>
                <div
                    className={clsx(styles.sidebar_header, {
                        [styles.noTitle]: !title
                    })}>
                    {
                        title &&
                        <p>{title}</p>
                    }
                    <div style={{cursor: "pointer"}} onClick={onCloseHandler}>
                        <Cross/>
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
};
