import {FriendsIcon} from "../../../../Assets/icons";
import clsx from "clsx";
import styles from '../index.module.scss'
import {header} from "../../Screens/Friends/Header/texts";
import {useGlobalValue} from "elum-state/react";
import {LANGUAGE_CODE} from "../../../state/elum";

export const Friends = () => {
    const langCode = useGlobalValue(LANGUAGE_CODE);
    return (
        <div className={clsx([styles.classic])}>
            {/*<FriendsIcon/>*/}
            <p className={styles.header_title}>{header.title[langCode]}</p>
        </div>
    )
}