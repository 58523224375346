import styles from './index.module.scss'
import {ReactComponent as CrossIcon} from '../icons/cross.svg'

export const Cross = () => {
    return (
        <div className={styles.cross}>
            <CrossIcon/>
        </div>
    )
}
