import {IProps} from "../../../../types/props";
import {FC} from "react";
import styles from './modal.module.scss'
import {Cross} from "../entities/cross";

interface IModal extends IProps {
    onClose: () => void;

}

export const Modal: FC<IModal> = ({children, onClose}) => {

    const onCloseHandler = () => {
        document.querySelector(`.${styles.modal_overlay}`)?.classList.add(styles.overlayOut);
        document.querySelector(`.${styles.modal_wrapper}`)?.classList.add(styles.wrapperOut);

        setTimeout(() => {
            onClose();
        }, 200);
    };


    return (
        <div className={styles.modal}>
            <div className={styles.modal_overlay} onClick={onCloseHandler}/>

            <div className={styles.modal_wrapper}>
                <div className={styles.modal_cross} onClick={onCloseHandler}>
                    <Cross/>
                </div>
                {children}
            </div>
        </div>
    )
}