import styles from "./Action.module.scss"
import React, {FC} from "react";
import {buttons} from "../texts";
import {TgStar} from "../../../../../Assets/icons";
import {ILangProp} from "../../../../../Struct/Screens/Top";
import {setter} from "elum-state/react";
import {SIDEBARS} from "../../../../state/elum";


export const TopAction: FC<ILangProp> = ({langCode}) => {


    const openDonuts = () => {
        return setter(SIDEBARS, 'donuts')
    }

    return (
        <div className={`${styles.action}`}>
            <button className={styles.action_button} onClick={openDonuts}>
                {<TgStar/>}
                <p>{buttons.main[langCode]}</p>
            </button>
        </div>

    )

}
