import styles from  './Game.module.scss'
import {TimeBlock} from "../../../Engine/Components/Screens/Game/TimeBlock";
import React from "react";
import clsx from "clsx";
export const Game = () => {
    //const [height, setHeight] = React.useState(0);

    // React.useEffect(() => {
    //     setHeight(Number(document.getElementById('menu')?.clientHeight));
    // }, [])
    //
    //
    // if (!height) return <></>;



    return (
        <div id='game-block' className={clsx([styles.game, 'fade-in'])}
            //style={{paddingBottom: `${height + 10}px`}}
        >
            <TimeBlock/>
            <div className={styles.flame}>
                <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 430 689"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMidYMid meet"
                >
                    <g filter="url(#filter0_f_11_159)">
                        <path
                            d="M154.006 432.173L219.566 108L293.168 432.173L428 715H0L154.006 432.173Z"
                            fill="url(#paint0_diamond_11_159)"
                            fill-opacity="0.4"
                        />
                    </g>
                    <defs>
                        <filter id="filter0_f_11_159" x="-108" y="0" width="644" height="823"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                            <feGaussianBlur stdDeviation="54" result="effect1_foregroundBlur_11_159"/>
                        </filter>
                        <radialGradient id="paint0_diamond_11_159" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                        gradientTransform="translate(214 715) rotate(-90) scale(494.593 239.678)">
                            <stop stop-color="#C9C4FF"/>
                            <stop offset="1" stop-color="#003CFF"/>
                        </radialGradient>
                    </defs>
                </svg>

                {/*<FlameAnimation/>*/}
            </div>
        </div>
    )
}
