export const header = {
    title: {
        ru: 'Пригласи друга по своей реферальной ссылке и получи',
        en: 'Invite a friends'
    },
    cards: {
        first: {
            ru: '24 часа за друга',
            en: '24 hours for a friend'
        },
        second: {
            ru: '72 часа за друга с премиум',
            en: '72 hours for a friend with premium'
        }
    },
    timer: {
        hours: {
            ru: 'ч.',
            en: 'h.'
        },
        minutes: {
            ru: 'м.',
            en: 'm.'
        },
        seconds: {
            ru: 'с.',
            en: 's.'
        }
    },
    description: {
        ru: 'на свой баланс',
        en: 'to your balance'
    },
    snackbar: {
        error: {
            en: 'Copy error',
            ru: 'Ошибка копирования'
        },
        success: {
            en: 'Copied',
            ru: 'Скопировано'
        }
    },
    main_btn: {
        en: 'Invite friends',
        ru: 'Пригласить друзей'
    }
}


