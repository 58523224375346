import React, {FC} from "react";
import {ITask} from "./index";
import styles from './Body.module.scss'
import {setter} from "elum-state/react";
import {PAYLOAD, SIDEBARS} from "../../../../state/elum";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {useTonAddress, } from "@tonconnect/ui-react";
import {BalanceDiamond} from "../../../../../Assets/icons";

type ITasksBody = {
    tasks: ITask[] | undefined[]
}

export const TasksBody: FC<ITasksBody> = ({tasks}) => {
    const walletAddress = useTonAddress();


    const clickOnTask = (id: number | undefined, payload: string | undefined, state: number | null) => {
        if (!id || (state !== null && state > 2)) return 0


        setter(SIDEBARS, 'task')
        setter(PAYLOAD, {id, payload})

    }
    const formatAddress = (address: string) => {
        if (address.length <= 10) {
            return address;
        }
        const firstPart = address.slice(0, 5);
        const lastPart = address.slice(-5);
        return `${firstPart}...${lastPart}`;
    };



    const genFooter = (el:  ITask | undefined) => {
        switch (el?.payload){
            case 'setter:ton:_':
                if (walletAddress !== '') {
                    return formatAddress(walletAddress)
                } else {
                    return `+ ${el.award} min.`
                }
            case 'make:transaction':
                return <><p>+ {el.award} min. and + 50 </p><BalanceDiamond/></>

        }
    }

    return (
        <>
            {tasks.map((el, i) =>
                <div className={styles.body_tasks__row} onClick={() => clickOnTask(el?.id, el?.payload, el!.state)} key={`${i}_task`}>
                    {
                        el ?
                            <>
                                <div className={styles['body_tasks__row-box']}>
                                    <img src={`./assets/tasks/${el.icon}`} className={styles['body_tasks__row-img']}/>
                                    <div className={styles['body_tasks__row-info']}>
                                        <p className={styles['body_tasks__row-title']}>{el.title}</p>
                                        <p className={styles['body_tasks__row-award']}>{genFooter(el)}</p>
                                    </div>
                                </div>
                                {
                                    el.state && el.state > 2 ? <DoneAllIcon className={styles['body_tasks__row-done']}/> : <></>
                                }
                            </>
                            : <></>
                    }
                </div>
            )}
        </>
    )
}
