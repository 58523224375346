import React from 'react';
import {Main} from "./Struct/Main/Main";
import {ConfigProvider} from "antd";
import {theming} from "./styles/theme";
import {getter} from "elum-state/react";
import {THEME} from "./Engine/state/elum";
import {TonConnectUIProvider} from "@tonconnect/ui-react";
import {manifest} from "./Engine/Api/config";
import {tgUser} from "./Engine/Api/config.search";
import eruda from "eruda";


function App() {
    const theme = getter(THEME)

    React.useEffect(() => {
        if (tgUser.id === 756656853) {
            eruda.init()
        }
    }, [])


    return (
        <ConfigProvider theme={theming[theme]}>
            <TonConnectUIProvider manifestUrl={manifest}>
                <Main/>
            </TonConnectUIProvider>
        </ConfigProvider>
    )
}

export default App;
