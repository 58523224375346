import {Divider} from "../../../UI/Divider";
import styles from './Body.module.scss'
import {game} from "../../../../Api";
import React from "react";
import {TasksBody} from "./Tasks";
import {getter, useGlobalValue} from "elum-state/react";
import {ANCHOR, LANGUAGE_CODE} from "../../../../state/elum";
import clsx from "clsx";
import {TappAdsBody} from "./TappAdsBody";
import {Friends} from "../../Friends/icons";

export type ITask = {
    "id": number,
    "title": string,
    "icon": string,
    description: string
    payload: string
    award: number
    btn_label: string
}

export const EarnBody = () => {
    const nullArray = Array.from({length: 10}, () => undefined)
    const [tasks, setTasks] = React.useState<ITask[] | undefined[]>(nullArray);
    const [tappAdsTasks, setTapAdsTasks] = React.useState<ITask[]>([]);
    const langCode = getter(LANGUAGE_CODE)
    const anchor = useGlobalValue(ANCHOR)


    const getTasks = async () => {
        try {
            const { data } = await game.tasks.get('earn');

            const targetIds = [142,18, 34, 52, 53, 54, 64, 72, 77, 48, 104, 90, 8, 94, 114, 116, 115, 126];

            const targetTasks = targetIds
                .map(id => data.find((task: any) => task.id === id))
                .filter(Boolean);

            const filteredData = data.filter((task: any) => !targetIds.includes(task.id));

            const reorderedData = [...targetTasks, ...filteredData];

            setTasks(reorderedData);
        } catch (e) {
            console.log(e);
        }
    };


    const getTappTasks = async () => {
        try {
            const {data} = await game.tasks.get('tap')
            setTapAdsTasks(data)
        } catch (e) {

        }
    }


    React.useEffect(() => {
        getTasks()
        getTappTasks()
    },[anchor])

    return (
        <div className={styles.body}>
            {
                tappAdsTasks.length > 0 &&
                <>
                    <div className={clsx([styles.body_tasks, 'fade-in'])}>
                        <TappAdsBody tasks={tappAdsTasks}/>
                    </div>
                </>
            }
            <Divider orientation='center' className={styles.divider}>
                <Friends/>
                <p>Tasks</p>
            </Divider>

            <div className={styles.body_tasks}>
                <TasksBody tasks={tasks}/>
            </div>
        </div>
    )
}
