import {FC} from "react";
import {FieldTimeOutlined} from "@ant-design/icons";
import styles from '../DailyBonus.module.scss'
import {ReactComponent as DoneIcon} from '../icons/done_icon.svg'
import {daily} from "../data/texts";
import clsx from "clsx";


type IDayBox = {
    langCode: 'ru' | 'en'
    active: boolean
    award: number
    collected: boolean
    day: number
    collectAward: ((a: number) => Promise<void> )
}

export const DayBox: FC<IDayBox> = ({collected, active,award,day, langCode, collectAward}) => {

    const collect = () => {
        active && collectAward(award)
    }


    return (
        <div className={
            clsx(
                styles.item,
                {[styles.item_collected]: collected},
                {[styles.item_active]: active},
                {[styles.item_notactive]: !active && !collected},
            )

        } onClick={collect}>

            <p>{daily.box.title[langCode]} {day}</p>
            <img src={'./assets/misc/gift.png'}/>
            <span className={clsx(
                {[styles.item_active_text]: !collected},
            )}>
                <p>+ {award} {daily.box.desc[langCode]}</p>
                {collected && <DoneIcon/>}
            </span>
        </div>
    )
}
