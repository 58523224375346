import { useState, useEffect, useCallback } from "react";
import { game } from "../Api";

const useGetHillInfo = () => {
    const [hillInfo, setHillInfo] = useState<Hill.Response | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const fetchHillInfo = useCallback(async () => {
        setIsLoading(true);
        try {
            const { data } = await game.hill.get();
            setHillInfo(data);
        } catch (error) {
            console.error("err:", error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchHillInfo();
    }, [fetchHillInfo]);

    return {
        hillInfo,
        isLoading,
        refetch: fetchHillInfo,
        setHillInfo
    };
};

export { useGetHillInfo };
