import {Drawer} from "antd";
import React, {useState} from "react";
import {getter, setter, useGlobalValue} from "elum-state/react";
import {ANCHOR, LANGUAGE_CODE, SIDEBARS} from "../../../../state/elum";
import styles from './DailyBonus.module.scss'
import {game} from "../../../../Api";
import {DailyBonusContent} from "./entity/Content";
import {daily} from "./data/texts";
import {CloseOutlined} from "@ant-design/icons";
import {Sidebar} from "../../PopUps/Sidebar";
import {useEnqueueSnackbar} from "../../../../Hooks/useSnackbar";

export type IDailyBonus = {
    collecting_days: number
    can_collect: boolean
}

export const DailyBonus = () => {
    const [open, setOpen] = useState(getter(SIDEBARS) === 'daily_bonus');
    const [dailyBonus, setDailyBonus] = useState<IDailyBonus>({} as IDailyBonus)
    const langCode = useGlobalValue(LANGUAGE_CODE)
    const {openSnackbar} = useEnqueueSnackbar()


    const getDailyBonusInfo = async () => {
        try {
            const {data} = await game.bonus.get()
            setDailyBonus(data)

        } catch (e) {
            console.log(e)
        }
    }

    const collectAward = async (a: number) => {
        try {
            await game.bonus.collect()
            openSnackbar({message: `Collected ${a} h.`, variant: 'success'})
            return getDailyBonusInfo()

        } catch (e) {
            console.log(e)
        }

    }

    const classNames = {
        wrapper: styles.sidebar_wrapper
    }


    const onClose = () => {
        setOpen(false);
        setTimeout(() =>
            setter(SIDEBARS, '')
        , 500)
    };



    React.useEffect(() => {
        getDailyBonusInfo()
    },[])

    if (dailyBonus.can_collect === undefined || dailyBonus.collecting_days === undefined) return <></>

    return (
        <Sidebar
            onClose={onClose}
            title={'Daily bonus'}
            className={classNames}
        >


            <DailyBonusContent {...dailyBonus} langCode={langCode} collectAward={collectAward}/>
            {/*<button className={`btn_primary ${styles.content_btn}`} onClick={collectAward}*/}
            {/*        disabled={!dailyBonus.can_collect}>*/}
            {/*    {daily.button[dailyBonus.can_collect ? 'action' : 'not_action'][langCode]}*/}
            {/*</button>*/}

        </Sidebar>
    )
}
